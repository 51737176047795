<template>
  <div>
    <v-card max-width="1000" outlined>
      <v-card-title class="blue-grey lighten-5 justify-space-between">
        <span class="font-weight-bold">Meal Type Tags ({{confTags.length}})</span>

        <v-text-field
          placeholder="Search meal tags"
          append-icon="mdi-magnify"
          style="max-width: 450px"
          v-model="search"
          class="white"
          hide-details
          clearable
          outlined
          rounded
          dense
        ></v-text-field>

        <v-btn @click="getTags()" :loading="status.getting" icon>
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text 
        v-if="!status.getting && confTags.length"
        class="pa-7"
      >
        <div class="d-flex flex-wrap gap-10">
          <v-chip
            v-for="tag in confTags" 
            class="px-5 py-4"
            :key="tag.id"
            outlined
            hover
          >
            <span class="mr-5" :class="tag.published ? 'primary--text' : 'grey--text'">{{ tag.name }}</span>
            
            <v-menu 
              :close-on-content-click="false"
              transition="slide-x-transition"
              offset-y 
              bottom 
              left 
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  class="mr-n2"
                  v-on="on"
                  x-small
                  icon
                >
                  <v-icon small>mdi-chevron-down</v-icon>
                </v-btn>
              </template>

              <v-list dense>
                <v-list-item @click="setToEdit(tag)">
                  <v-list-item-title>Edit</v-list-item-title>
                </v-list-item>
                <v-list-item @click="updateTagField({
                  tag,
                  field: 'published',
                  value: !tag.published
                })">
                  <v-list-item-title>{{ tag.published ? 'Unpublish' : 'Publish' }}</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title class="caption grey--text">User Roles</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-for="(role, i) in ['dietitian', 'personal_trainer', 'member', 'nutritionist', 'naturopath']"
                  :key="i"
                >
                  <v-checkbox
                    @change="updateTagField({
                      tag,
                      silent: true,
                      field: 'roles',
                      value: tag.roles,
                    })"
                    v-model="tag.roles"
                    :label="role"
                    :value="role"
                    class="mt-0 pt-0"
                    hide-details
                    multiple
                    dense
                  >
                    <template #label>
                      <v-list-item-title>{{ role | startCase }}</v-list-item-title>
                    </template>
                  </v-checkbox>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item @click="setToDelete(tag)">
                  <v-list-item-title> <span class="red--text">Delete</span> </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-chip>
        </div>
      </v-card-text>
      
      <div class="pa-7" v-if="!status.getting && !confTags.length">
        <v-alert type="info" border="left" class="mb-0">
          You don't have any tags yet.
        </v-alert>
      </div>
      <preloader v-if="status.getting"/>
    </v-card>

    <v-card
      color="transparent"
      max-width="1000"
      class="mt-5"
      flat
    >

      <v-alert v-if="status.error" text border="left" class="white" type="error">
        {{ status.error }}
      </v-alert>

      <v-form v-if="!toEdit" ref="tagForm" @submit.prevent="validateForm()">
        <v-text-field
          placeholder="Meal tag name"
          :rules="[rules.required]"
          v-model="data.name"
          hide-details
          outlined
        >
          <v-btn
            :loading="status.adding"
            @click="validateForm()"
            class="mt-n3 mr-n1"
            color="accent"
            slot="append"
            depressed
            large
            dark
          >Add</v-btn>
        </v-text-field>
      </v-form>

      <v-form v-else ref="updateForm" @submit.prevent="validateUpdateForm()">
        <v-text-field
          placeholder="New meal tag name"
          :rules="[rules.required]"
          v-model="toEdit.name"
          hide-details
          outlined
        >
          <template v-slot:append>
            <div class="d-flex align-center mt-n3 mr-n1">
              <v-btn
                @click="validateUpdateForm()"
                :loading="status.updating"
                class="mr-2"
                color="accent"
                depressed
                large
                dark
              >Update</v-btn>

              <v-btn
                :disabled="status.updating"
                @click="cancelEdit()"
                depressed
                large
              >Cancel</v-btn>
            </div>
          </template>
        </v-text-field>
      </v-form>
    </v-card>

    <ConfirmDelete
      @confirmed="deleteConfirmed()"
      @cancel="showDelete = false"
      :deleting="status.deleting"
      :showDialog="showDelete"
      :message="deleteMessage"
    />
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import _ from 'lodash'
import rules from '@/rules'

export default {
  name: 'MealTags',

  metaInfo: {
    title: 'Meal Tags'
  },

  data() {
    return {
      rules,
      toEdit: null,
      search: null,
      toDelete: null,
      originalValue: {},
      showDelete: false,
      deleteMessage: null,
    }
  },

  computed: {
    ...mapState({
      data: state => state.tags.data,
      tags: state => state.tags.tags,
      status: state => state.tags.status,
    }),

    confTags: function () {
      let tags = _.orderBy(this.tags, ['name'], ['asc'])

      if (this.search) {
        tags = tags.filter(tag => {
          return tag.name.toLowerCase().includes(this.search.toLowerCase())
        })
      }

      return tags
    }
  },

  methods: {
    ...mapActions('tags', [
      'updateTagField',
      'updateTag',
      'deleteTag',
      'getTags',
      'addTag',
    ]),

    validateForm() {
      if (this.$refs.tagForm.validate()) {
        this.addTag()
      }
    },

    setToEdit(tag) {
      this.toEdit = tag
      _.assign(this.originalValue, tag)
    },

    cancelEdit() {
      let tag = this.tags.find(tag => tag.id == this.toEdit.id)
      this.toEdit = null
      _.assign(tag, this.originalValue)
    },

    validateUpdateForm() {
      if (this.$refs.updateForm.validate()) {
        Promise.all([this.updateTag(this.toEdit)])
        .then(() => {
          this.toEdit = null
        })
      }
    },

    setToDelete(tag) {
      this.toDelete = tag
      this.showDelete = true
      this.deleteMessage = `Are you sure want to delete meal tag "${this.toDelete.name}"?`
    },

    deleteConfirmed() {
      Promise.all([this.deleteTag(this.toDelete)])
      .then(() => {
        this.toDelete = null
        this.showDelete = false
      })
    }
  },

  created() {
    if (!this.status.firstLoad) this.getTags()
  }
}

</script>
